"use strict";
import dayjs from "dayjs";
import { getAttachmentType } from "../utils/attachment-type";
export var ModalType = /* @__PURE__ */ ((ModalType2) => {
  ModalType2["MOVE"] = "move";
  ModalType2["CLONE"] = "clone";
  ModalType2["TRASH"] = "trash";
  ModalType2["RESEND"] = "resend";
  ModalType2["DELETE"] = "delete";
  ModalType2["ADD_RECORD"] = "add record";
  ModalType2["ADD_TO_EVENT"] = "add to event";
  ModalType2["SEARCH"] = "search";
  ModalType2["CREATE_APPROVAL"] = "create approval";
  ModalType2["EMAIL_EXTERNAL"] = "email external";
  ModalType2["ADD_FORM"] = "add form";
  ModalType2["SEND_SMS"] = "send sms";
  ModalType2["SEND_FORM"] = "send form";
  ModalType2["SEND_EMAIL"] = "send email";
  ModalType2["INVITE_TO_TIMESLOT"] = "invite to timeslot";
  ModalType2["ADD_EDIT_TASK"] = "add edit task";
  ModalType2["TASK_WORKFLOW"] = "task workflow";
  ModalType2["EXPORT_TO_ONBOARD"] = "export to onboard";
  ModalType2["ONBOARD_EXPRESS"] = "onboard express";
  return ModalType2;
})(ModalType || {});
export const defaultSections = [
  "Candidate Tags",
  "Felix AI Summary",
  "Responses",
  "Notepad",
  "Actions",
  "Attachments",
  "Application questionnaire"
];
export const getApplicationRatingColor = (rating) => {
  if (rating === null) return { color: "#666666", background: "#E5E5E5" };
  if (rating <= 34) {
    return { color: "rgba(227,125,122)", background: "rgba(227,125,122,0.12)" };
  } else if (rating <= 49) {
    return { color: "rgba(229,153,92)", background: "rgba(229,153,92,0.12)" };
  } else if (rating <= 74) {
    return { color: "rgba(254,213,0)", background: "rgba(254,213,0,0.12)" };
  } else if (rating <= 90) {
    return { color: "rgba(140,207,149)", background: "rgba(140,207,149,0.12)" };
  } else if (rating <= 100) {
    return { color: "rgba(62,170,62)", background: "rgba(62,170,62,0.12)" };
  }
};
export const timeStringToSeconds = (time) => {
  const [hour, minute, second] = time.split(":").map((t) => parseInt(t));
  return dayjs.duration({ hours: hour, minutes: minute, seconds: second }).asSeconds();
};
export const secondsToTimeString = (seconds) => {
  return dayjs.duration(seconds, "seconds").format("HH:mm:ss");
};
export const checkMissingPlaceholders = (placeholders, body) => {
  const missingPlaceholders = placeholders.filter((placeholder) => !body.includes(placeholder));
  return !!missingPlaceholders.length;
};
export const InitialTimeStampState = {
  hasTyped: false,
  timestampError: { nameMessage: "", valueMessage: "", id: null }
};
export const categorizeAttachments = (attachments) => {
  return attachments.reduce(
    (acc, attachment) => {
      const { isVideo } = getAttachmentType(attachment);
      if (isVideo) {
        acc.videos.push(attachment);
      } else {
        acc.files.push(attachment);
      }
      return acc;
    },
    { files: [], videos: [] }
  );
};
export const VIDEO_INTERVIEW_SOURCE_INDEX = 1;
export const VIDEO_INTERVIEW_DISPLAY_NAME = "Video Interview";
export const LINKEDIN_URL_REGEX = /^(https:\/\/)(|www\.|[a-z]{2}\.)?linkedin.com\/in\/([a-zA-Z0-9-]*)/gm;
export const ADD_FORM_PLACEHOLDER = "{FORM}";
export const INVITE_TO_TIMESLOT_PLACEHOLDER = "{INTERVIEW_URL}";
