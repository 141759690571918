import React, { useState, Dispatch } from 'react';
import { Button, Dialog, Stack, Tooltip } from '@mui/material';
import { CheckCircle as CheckCircleIcon, FileCopy as FileCopyIcon } from '@mui/icons-material';
import OldIntegrationsLink from './OldIntegrationsLink';
import ExportButton from './ExportButton';
import OnboardExpressLogo from './Logos/OnboardExpressLogo.png';
import { ApplicationState, ApplicationAction } from '../types';
import { ModalType } from '../config';
import OnboardExpress from './Modals/OnboardExpress';
import { ScoutOnboardLogo } from './Logos/Logos';
import { useQueryClient } from '@tanstack/react-query';
import { IUserPermissions } from '../../Components/sharedTypes';
import { styles } from './styles';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import { IApplication } from '../types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function IntegrationsTab({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const [alreadyExportedModalOpen, setAlreadyExportedModalOpen] = useState<boolean>(false);
  const { modalsOpen } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);

  if (!application) return null;

  return (
    <Stack>
      <Stack sx={{ flexDirection: 'row', columnGap: 4 }}>
        {permissions?.Integrations?.['Export To Revolve Onboard'] && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={styles.integrationCardImageContainer}
              component="img"
              src={OnboardExpressLogo}
            />
            <ExportButton
              onClick={() =>
                application.revolve_record_id
                  ? setAlreadyExportedModalOpen(true)
                  : dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.ONBOARD_EXPRESS })
              }
            />
          </Stack>
        )}
        {permissions?.Integrations?.['Export To SCOUT Onboard'] && (
          <Stack sx={styles.integrationCard}>
            <Stack sx={styles.integrationCardImageContainer}>
              <ScoutOnboardLogo />
            </Stack>
            <ExportButton
              onClick={() =>
                dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.EXPORT_TO_ONBOARD })
              }
            />
          </Stack>
        )}
      </Stack>
      <OldIntegrationsLink />
      {modalsOpen === ModalType.ONBOARD_EXPRESS && (
        <OnboardExpress ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      <Dialog open={alreadyExportedModalOpen} onClose={() => setAlreadyExportedModalOpen(false)}>
        <Stack sx={styles.alreadyExportedModal}>
          <CheckCircleIcon sx={{ color: '#5BC4BF', fontSize: '4rem' }} />
          <Stack sx={styles.modalTitle}>Export to Revolve Onboard</Stack>
          <Stack sx={{ alignItems: 'center', color: '#333333', paddingBottom: 2 }}>
            <Stack>This application has already been exported to Revolve.</Stack>
            <Stack sx={{ flexDirection: 'row', columnGap: 1, alignItems: 'center' }}>
              <Stack>Revolve record id is</Stack>
              <Stack fontWeight="bold">{application.revolve_record_id}</Stack>
              <CopyToClipboard
                id="copy-revolve-id-button"
                text={application.revolve_record_id}
                onCopy={() =>
                  dispatch({
                    type: 'SET_SNACKBAR',
                    payload: {
                      message: 'Revolve record id has been copied to clipboard',
                      state: 'success'
                    }
                  })
                }
              >
                <Tooltip title={'Copy ID'} placement={'bottom'}>
                  <FileCopyIcon fontSize="small" sx={{ color: '#666666', cursor: 'pointer' }} />
                </Tooltip>
              </CopyToClipboard>
            </Stack>
          </Stack>
          <Button
            sx={sharedClasses.genericButton}
            onClick={() => setAlreadyExportedModalOpen(false)}
          >
            Exit
          </Button>
        </Stack>
      </Dialog>
    </Stack>
  );
}
