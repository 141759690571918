import React, { Dispatch, useState, useRef } from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import StyledModal from '../../../Components/GenericModal/StyledModal';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../API';
import { ApplicationAction, ApplicationState, IApplication } from '../../types';
import { ModalType } from '../../config';
import ModalFooterButtons from '../../../Components/GenericModal/ModalFooterButtons';
import { styles } from '../styles';
import { FormTextField } from '../../../Components/CustomUIElements/FormTextField';
import { TCandidate } from '../../../Candidates/types';
import { SingleDatePicker } from '../../../Components/CustomUIElements/SingleDatePicker';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import dayjs from 'dayjs';
import { validateEmail } from '../../../Candidates/EditCandidate';
import { scrollToElement } from '../../../utils/scroll-to-element';

export default function OnboardExpress({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const { modalsOpen, userEmail } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const [positionTitle, setPositionTitle] = useState<string>(application?.job.title || '');
  const [candidate, setCandidate] = useState<TCandidate | null>(application?.candidate || null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [dateError, setDateError] = useState<string>('');
  const [workType, setWorkType] = useState<string | null>(null);
  const [entity, setEntity] = useState<string | null>(null);
  const [template, setTemplate] = useState<string | null>(null);
  const [state, setState] = useState<string | null>(null);
  const [createdBy, setCreatedBy] = useState<string>(userEmail);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const fieldRefs = {
    template: useRef<HTMLInputElement>(null),
    positionTitle: useRef<HTMLInputElement>(null),
    createdBy: useRef<HTMLInputElement>(null),
    startDate: useRef<HTMLInputElement>(null),
    firstName: useRef<HTMLInputElement>(null),
    lastName: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null)
  };

  const { data: revolveData, isLoading: loadingRevolveData } = useQuery({
    queryKey: ['revolve data'],
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getRevolveData(application.id);
        return res;
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting onboard express data, ${error}`,
          state: 'error'
        }
      })
  });

  const { mutate: revolveExport, isLoading: loadingRevolveExport } = useMutation({
    mutationFn: async () => {
      if (application && candidate) {
        return await Api.revolveExport(application.id, {
          template_name: template || '',
          position_title: positionTitle,
          worktype: workType || '',
          entity_name: entity || '',
          created_by: createdBy,
          candidate_first_name: candidate.firstname,
          candidate_last_name: candidate.lastname,
          candidate_email: candidate.email,
          candidate_mobile_no: candidate.phone1,
          candidate_phone_no: candidate.phone2,
          candidate_addr_street: candidate.address_details.street1 || '',
          candidate_addr_street1: candidate.address_details.street2 || '',
          candidate_addr_suburb: candidate.address_details.suburb || '',
          candidate_addr_state: state || '',
          candidate_addr_postcode: candidate.address_details.postcode || '',
          start_date: dayjs(startDate).format('DD/MM/YYYY')
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['application'], { exact: true });
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: 'Revolve export has been successfully exported', state: 'success' }
      });
      handleClose();
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in exporting to Revolve Onboard, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const validateInputs = () => {
    if (!candidate) return false;
    setErrors({});
    const inputErrors: Record<string, string> = {};
    if (!template) inputErrors.template = 'Please select a template';
    if (!positionTitle.trim()) inputErrors.positionTitle = 'Position title can not be empty';
    if (!createdBy.trim()) inputErrors.createdBy = 'Created by can not be empty';
    if (!startDate) inputErrors.startDate = 'Please select a start date';
    if (!candidate.firstname.trim()) inputErrors.firstName = 'First name can not be empty';
    if (!candidate.lastname.trim()) inputErrors.lastName = 'Last name can not be empty';
    if (!validateEmail(candidate.email)) inputErrors.email = 'Please enter a proper email address';
    setErrors(inputErrors);
    if (inputErrors.template) {
      scrollToElement(fieldRefs.template);
    } else if (inputErrors.positionTitle) {
      scrollToElement(fieldRefs.positionTitle);
    } else if (inputErrors.createdBy) {
      scrollToElement(fieldRefs.createdBy);
    } else if (inputErrors.startDate) {
      scrollToElement(fieldRefs.startDate);
    } else if (inputErrors.firstName) {
      scrollToElement(fieldRefs.firstName);
    } else if (inputErrors.lastName) {
      scrollToElement(fieldRefs.lastName);
    } else if (inputErrors.email) {
      scrollToElement(fieldRefs.email);
    }
    return !Object.keys(inputErrors).length;
  };

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });

  if (!candidate) return null;

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.ONBOARD_EXPRESS}
      label="Onboard express modal"
      handleClose={handleClose}
      styleOverrides={styles.modalStyleOverrides}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>Export to Revolve Onboard</Stack>
        <Stack sx={{ overflow: 'auto', rowGap: 2 }}>
          <Autocomplete
            disablePortal
            options={revolveData?.template_names || []}
            value={template}
            sx={{ ...sharedClasses.formAutocomplete, width: '48%', marginTop: 3, flexGrow: 1 }}
            loading={loadingRevolveData}
            loadingText="Loading templates..."
            ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="NEA letter template"
                placeholder="Please select"
                InputLabelProps={{ shrink: true }}
                sx={styles.autoCompleteTextfield}
                required
                helperText={errors.template}
                ref={fieldRefs.template}
              />
            )}
            onChange={(event, value) => setTemplate(value)}
          />
          <FormTextField
            label="Position title"
            value={positionTitle}
            styles={{ width: '48%' }}
            required
            onChange={(e) => setPositionTitle(e.target.value)}
            error={errors.positionTitle}
            innerRef={fieldRefs.positionTitle}
          />
          <Autocomplete
            disablePortal
            options={revolveData?.entity_names || []}
            value={entity}
            sx={{ ...sharedClasses.formAutocomplete, width: '48%', marginTop: 3, flexGrow: 1 }}
            loading={loadingRevolveData}
            loadingText="Loading entity names..."
            ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Entity name"
                placeholder="Please select"
                InputLabelProps={{ shrink: true }}
                sx={styles.autoCompleteTextfield}
              />
            )}
            onChange={(event, value) => setEntity(value)}
          />
          <FormTextField
            label="Created by (email)"
            value={createdBy}
            styles={{ width: '48%' }}
            required
            onChange={(e) => setCreatedBy(e.target.value)}
            error={errors.createdBy}
            innerRef={fieldRefs.createdBy}
          />
          <Autocomplete
            disablePortal
            options={revolveData?.worktypes || []}
            value={workType}
            sx={{ ...sharedClasses.formAutocomplete, width: '48%', marginTop: 3, flexGrow: 1 }}
            loading={loadingRevolveData}
            loadingText="Loading work types..."
            ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Work type"
                placeholder="Please select"
                InputLabelProps={{ shrink: true }}
                sx={styles.autoCompleteTextfield}
              />
            )}
            onChange={(event, value) => setWorkType(value)}
          />
          <Stack>
            <SingleDatePicker
              value={startDate}
              label="Start date"
              inputStyles={{ ...sharedClasses.datePicker, width: '48%' }}
              onChange={(value) => {
                if (!value?.isValid()) {
                  setDateError('Please input a valid date');
                } else {
                  setDateError('');
                  setStartDate(dayjs(value.toDate()).toDate());
                }
              }}
              error={dateError || errors.startDate}
              required
            />
          </Stack>
          <Stack sx={styles.modalDoubleColumn}>
            <FormTextField
              label="First name"
              value={candidate.firstname || ''}
              required
              fullWidth
              onChange={(e) => setCandidate({ ...candidate, firstname: e.target.value })}
              error={errors.firstName}
              innerRef={fieldRefs.firstName}
            />
            <FormTextField
              label="Last name"
              value={candidate.lastname || ''}
              required
              fullWidth
              onChange={(e) => setCandidate({ ...candidate, lastname: e.target.value })}
              error={errors.lastName}
              innerRef={fieldRefs.lastName}
            />
          </Stack>
          <FormTextField
            label="Email"
            value={candidate.email}
            styles={{ width: '48%' }}
            required
            onChange={(e) => setCandidate({ ...candidate, email: e.target.value })}
            error={errors.email}
            innerRef={fieldRefs.email}
          />
          <Stack sx={styles.modalDoubleColumn}>
            <FormTextField
              label="Phone"
              value={candidate.phone1}
              onChange={(e) => setCandidate({ ...candidate, phone1: e.target.value })}
              fullWidth
            />
            <FormTextField
              label="Mobile"
              value={candidate.phone2}
              onChange={(e) => setCandidate({ ...candidate, phone2: e.target.value })}
              fullWidth
            />
          </Stack>
          <Stack sx={styles.modalDoubleColumn}>
            <FormTextField
              label="Street address"
              value={candidate?.address_details?.street1 || ''}
              fullWidth
              onChange={(e) =>
                setCandidate({
                  ...candidate,
                  address_details: { ...candidate.address_details, street1: e.target.value }
                })
              }
            />
            <FormTextField
              label="Street address cont'd"
              value={candidate?.address_details?.street2 || ''}
              fullWidth
              onChange={(e) =>
                setCandidate({
                  ...candidate,
                  address_details: { ...candidate.address_details, street2: e.target.value }
                })
              }
            />
          </Stack>
          <Stack sx={styles.modalDoubleColumn}>
            <FormTextField
              label="City, town or suburb"
              value={candidate?.address_details?.suburb || ''}
              fullWidth
              onChange={(e) =>
                setCandidate({
                  ...candidate,
                  address_details: { ...candidate.address_details, suburb: e.target.value }
                })
              }
            />
            <FormTextField
              label="Postcode or zipcode"
              value={candidate?.address_details?.postcode || ''}
              fullWidth
              onChange={(e) =>
                setCandidate({
                  ...candidate,
                  address_details: { ...candidate.address_details, postcode: e.target.value }
                })
              }
            />
          </Stack>
          <Autocomplete
            disablePortal
            options={revolveData?.states || []}
            value={state}
            sx={{ ...sharedClasses.formAutocomplete, width: '48%', marginTop: 3, flexGrow: 1 }}
            loading={loadingRevolveData}
            loadingText="Loading states..."
            ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="State, region or province"
                placeholder="Please select"
                InputLabelProps={{ shrink: true }}
                sx={styles.autoCompleteTextfield}
              />
            )}
            onChange={(event, value) => setState(value)}
          />
        </Stack>
        <ModalFooterButtons
          primaryButtonText="Export"
          primaryButtonCallback={() => validateInputs() && revolveExport()}
          isLoading={loadingRevolveExport}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          primaryButtonMinWidth="95px"
        />
      </Stack>
    </StyledModal>
  );
}
